


































































































































































































.main-content {
  margin: 20px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  .main-manager {
    .search-box {
      width: 260px;
      margin: 20px 0 0 20px;
    }
    .search-btn {
      margin-left: 10px;
    }
    .main-goodsOptions {
      margin: 20px 20px 0 20px;
      float: right;
    }
  }
}
.content {
  flex: 1;
  height: 1%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.goods-pic {
  min-width: 200px;
  height: 100px;
}
